import React from "react";
import CoreTeam2k23 from "./CoreTeam2k23";
import WebTeam from "./WebTeam";

function Team2k23() {
  return (
    <>
      <CoreTeam2k23 />
      <WebTeam />
    </>
  );
}

export default Team2k23;
